<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="title" label="问题">
						<a-input v-model:value="formState.title" placeholder="请输入问题"></a-input>
					</a-form-item>

					<a-form-item class="ui-form__item" name="moduleId" label="所属模块">
						<selectClassify v-model:value="formState.moduleId" :allowClear="true"></selectClassify>
					</a-form-item>

					<a-form-item class="ui-form__item" name="isDisabled" label="状态">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button type="primary"
							@click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<cTable ref="cTable" isPage isRequest :searchData="searchData" :requestFun="getQuestionList" :columns="columns" :scroll="{ x: 950 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'isHot'">
							{{ record.isHot ? '是' : '否' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-if="record.isDisabled" @click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-else @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</cTable>
			</div>
		</a-spin>

	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
	import selectClassify from '@/views/operation/copyWriting/problem/components/selectClassify.vue';
	import cTable from '@/components/cTable/index.vue';
	import { getQuestionList, deleteQuestion, disabledQuestion } from '@/service/modules/operation.js';
	export default {
		components: {
			Icon,
			selectClassify,
			temp,
			cTable
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				showAll: false,
				formState: {},
				searchData: {},
				columns: [{
					title: '标题',
					dataIndex: 'title',
					width: 100
				}, {
					title: '所属模块',
					dataIndex: 'moduleName',
					width: 100
				}, {
					title: '优先级',
					dataIndex: 'sort',
					width: 100
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '是否猜你想问',
					key: 'isHot',
					width: 120
				}, {
					title: '操作',
					key: 'action',
					width: 100
				}],
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getQuestionList: getQuestionList,
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData(true);
			},
			getData(isRef) {
				if (isRef) {
					this.$refs.cTable.refQuery();
				} else {
					this.$refs.cTable.toQuery();
				}
			},
			onAdd() {
				this.id = 0;
				this.isEdit = false;
				this.isSee = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.isSee = false;
				this.showModal = true;
			},
			onDelete(item) {
				this.$confirm({
					title:'提示',
					content: '确定删除吗？',
					onOk: async ()=> {
						this.loading = true;
						let ret = await deleteQuestion({
							id: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('删除成功');
							this.getData();
						}
					}
				})
			},
			onDisabled(item) {
				this.$confirm({
					title:'提示',
					content: `确定${ item.isDisabled ? '启用' : '禁用' }吗？`,
					onOk: async ()=> {
						this.loading = true;
						let ret = await disabledQuestion({
							id: item.id,
							isDisabled: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.getData();
						}
					}
				})
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData(true);
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>