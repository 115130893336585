<template>
	<div>
		<Header :title="isEdit ? '修改问题' : '新增问题'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 5, xxl: 4}" :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">

				<a-form-item label="标题" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.title" placeholder="请输入标题"></a-input>
				</a-form-item>

				<a-form-item class="ui-form__item" name="moduleId" label="所属模块" :rules="[{required: true, message: '必填项不允许为空'}]">
					<selectClassify v-model:value="modelRef.moduleId"></selectClassify>
				</a-form-item>

				<a-form-item label="问题" name="question" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.question" placeholder="请输入问题"></a-input>
				</a-form-item>

				<a-form-item label="回答" name="answer" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.answer" placeholder="请输入回答"></a-input>
				</a-form-item>

				<a-form-item label="优先级" name="sort" extra="优先级越高越靠前，不填则为0">
					<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入优先级"></a-input-number>
				</a-form-item>

				<a-form-item label="状态" name="isDisabled" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-radio-group v-model:value="modelRef.isDisabled">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<a-form-item label="是否猜你想问" name="isHot" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-switch v-model:checked="modelRef.isHot"></a-switch>
				</a-form-item>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>

			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import selectClassify from '@/views/operation/copyWriting/problem/components/selectClassify.vue';
	import { getQuestionDetail, saveQuestion, updateQuestion } from '@/service/modules/operation.js';
	export default {
		components: {
			Header,
			selectClassify
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
					isDisabled: 0,
					isHot: false
				},
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				let ret = await getQuestionDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.modelRef = ret.data;
				}
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				this.loading = true;
				let ret;
				if (postData.id) {
					ret = await updateQuestion(postData);
				} else {
					ret = await saveQuestion(postData);
				}
				this.loading = false
				if (ret.code === 200) {
					this.$message.success('操作成功');
					this.onBack(true);
				}
			},
			handleOpenChange1(open) {
				if (open) {
					this.mode1 = 'time';
				}
			},
			handlePanelChange1(val, mode) {
				this.mode1 = mode
			},
		}
	}
</script>

<style scoped>

</style>